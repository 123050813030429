/* Classes for all elements rendered in Charts.js */

@import url("../ClassShaperRootStyles.css");


.spacer-row {
    height: 30px;
}


/* Bubble Chart Styles */

.bubContainer {
    position: relative !important;
    height: var(--bubbleChartContainerHeight) !important;
    width: var(--bubbleChartContainerWidth) !important;
}


/* Net Totals Table Styles */

.total-metric-header {
    font-weight: bold !important;
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255) !important;
}

.total-last-year-header {
    font-weight: bold !important;
    background-color: rgb(201, 201, 210);
    border-color: rgb(201, 201, 210) !important;
    color: rgb(0, 0, 0) !important;
}

.total-model-one-header {
    font-weight: bold !important;
    background-color: rgb(155,194, 230);
    border-color: rgb(155,194, 230) !important;
    color: rgb(255, 255, 255) !important;
}

.total-model-two-header {
    font-weight: bold !important;
    background-color: rgb(47,117, 181);
    border-color: rgb(47,117, 181) !important;
    color: rgb(255, 255, 255) !important;
}

.total-model-three-header {
    font-weight: bold !important;
    background-color: rgb(37,78,120);
    border-color: rgb(37,78,120) !important;
    color: rgb(255, 255, 255) !important;
}

.total-cell {
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255) !important;
}


.total-container {
    text-align: center;
    background-color: rgb(255, 255, 255);
    padding: 10px;
}

.total-head {
    border-bottom: 0px;
}


/* Assumption Group Table Styles */

.assumption-container {
    text-align: center;
    background-color: rgb(255, 255, 255);
    padding: 10px;
}

.assumption-rows {
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255) !important;
}

.assumption-name-header {
    border-color: rgb(48, 84, 150) !important;
    background-color: rgb(48, 84, 150);
    font-weight: bold !important;
    color: rgb(255, 255, 255) !important;
}

.assumption-metric-header {
    border-color: rgb(221, 235, 247) !important;
    background-color: rgb(221, 235, 247);
    font-weight: bold !important;
}



/* Admitted Population Table Styles */

.admitted-table-container {
    background-color: rgb(226, 239, 218) !important;
    text-align: center;
    padding: 20px !important;
}

.admitted-hidden-row {
    visibility: hidden;
}

.admitted-metric-header {
    font-weight: bold !important;
    background-color: rgb(226, 239, 218);
    border-color: rgb(226, 239, 218) !important;
}

.admitted-last-year-header {
    font-weight: bold !important;
    background-color: rgb(201, 201, 210);
    border-color: rgb(201, 201, 210) !important;
    color: rgb(0, 0, 0) !important;
}

.admitted-current-year-header {
    font-weight: bold !important;
    background-color: rgb(55, 86, 35);
    border-color: rgb(55, 86, 35) !important;
    color: rgb(255, 255, 255) !important;
}

.admitted-metric-cell {
    background-color: rgb(226, 239, 218);
    border-color: rgb(226, 239, 218) !important;
}

.admitted-metric-cell-hidden {
    background-color: rgb(226, 239, 218);
    border-color: rgb(226, 239, 218) !important;
    visibility: hidden;
}

.admitted-last-year-cell {
    background-color: rgb(242, 242, 242);
    border-color: rgb(242, 242, 242) !important;
    color: rgb(0, 0, 0);
}

.admitted-current-year-cell {
    background-color: rgb(226, 239, 218);
    border-color: rgb(226, 239, 218) !important;
    color: rgb(0, 0, 0);
}


/* Enrolled Population Tabel Styles */

.enrolled-table-container {
    background-color: rgb(221, 235, 247) !important;
    text-align: center;
    padding: 20px;
}

.enrolled-metric-header {
    border-color: rgb(221, 235, 247) !important;
    background-color: rgb(221, 235, 247);
    font-weight: bold !important;
}

.enrolled-last-year-header {
    font-weight: bold !important;
    background-color: rgb(201, 201, 210);
    border-color: rgb(201, 201, 210) !important;
    color: rgb(0, 0, 0) !important;
}

.enrolled-model-one-header {
    font-weight: bold !important;
    background-color: rgb(155,194, 230);
    border-color: rgb(155,194, 230) !important;
    color: rgb(255, 255, 255) !important;
}

.enrolled-model-two-header {
    font-weight: bold !important;
    background-color: rgb(47,117, 181);
    border-color: rgb(47,117, 181) !important;
    color: rgb(255, 255, 255) !important;
}

.enrolled-model-three-header {
    font-weight: bold !important;
    background-color: rgb(37,78,120);
    border-color: rgb(37,78,120) !important;
    color: rgb(255, 255, 255) !important;
}

.enrolled-current-year-title-header {
    font-weight: bold !important;
    background-color: rgb(47,117, 181);
    border-color: rgb(47,117, 181) !important;
    color: rgb(255, 255, 255) !important;
}

.enrolled-metric-cell {
    border-color: rgb(221, 235, 247) !important;
    background-color: rgb(221, 235, 247);
}

.enrolled-last-year-cell {
    background-color: rgb(242, 242, 242);
    color: rgb(0, 0, 0);
    border-color: rgb(242, 242, 242) !important;
}

.enrolled-model-one-cell {
    background-color: rgb(189, 215, 238);
    color: rgb(0, 0, 0);
    border-color: rgb(189, 215, 238) !important;
}

.enrolled-model-two-cell {
    background-color: rgb(155, 194, 230);
    color: rgb(0, 0, 0);
    border-color: rgb(155, 194, 230) !important;
}

.enrolled-model-three-cell {
    background-color: rgb(47, 117, 181);
    color: rgb(255, 255, 255) !important;
    border-color: rgb(47, 117, 181) !important;
}