/* Classes for all elements rendered in Unauthorized.js */

@import url("../ClassShaperRootStyles.css");


.unauthorizedContainer {
    text-align: var(--unauthorizedContainerTextAlign);
}

.unauthorizedIcon {
    width: var(--unauthorizedIconWidth) !important;
    height: var(--unauthorizedIconHeight) !important;
    position: var(--unauthorizedIconPosition);
    left: var(--unauthorizedIconLeft);
    top: var(--unauthorizedIconTop);
}