/* Classes for all elements rendered in ClassShaper.js */

@import url("../ClassShaperRootStyles.css");

.arrowIcon {
    margin-top: var(--arrowIconMarginTop) !important;
}

.modelSelectForm {
    margin-top: var(--modelSelectFormMarginTop) !important;
    margin-right: var(--modelSelectFormMarginRight) !important;
    width: 69px;
}

.selectTableContainer {
    text-align: var(--selectTableContainerTextAlign);
    height: var(--selectTableContainerHeight);
}

.StudentInputFormContainer {
    height: var(--studentInputFormContainerHeight);
}

.scenarioButtons {
    width: var(--scenarioButtonWidth);
    margin-bottom: var(--scenarioButtonMarginBottom) !important;
}

.scenarioButtonIcons {
    margin-right: var(--scenarioButtonIconMarginRight);
}