/* All Style Settings for Class Shaper */

:root {
    /* Top Level Dashboard Component Styles */

    --arrowIconMarginTop: 10px;
    --modelSelectFormMarginTop: 5px;
    --modelSelectFormMarginRight: 5%;
    --selectTableContainerTextAlign: center;
    --selectTableContainerHeight: 500px;
    --studentInputFormContainerHeight: 400px;
    --scenarioButtonWidth: 15%;
    --scenarioButtonMarginBottom: 30px;
    --scenarioButtonIconMarginRight: 5px;
    /* Form Component Styles */

    --scenarioFormContainerPosition: absolute;
    --scenarioFormContainerTop: 50%;
    --scenarioFormContainerLeft: 50%;
    --scenarioFormContainerTranslate: translate(-50%, -50%);
    --scenarioFormContainerWidth: 650px;
    --scenarioFormContainerHeight: 500px;
    --scenarioFormContainerBorder: 2px solid #000;
    --scenarioFormContainerBoxShadow: 24;
    --scenarioFormContainerTextAlign: center;
    --scenarioFormContainerBackgroundColor: rgb(255, 255, 255);
    --scenarioFormHeaderPadding: 20px;
    --scenarioFormTextBoxWidth: 500px;
    --scenarioFormSelectWidth: 500px;
    --uploadFormDropAreaDisplay: flex;
    --uploadFormDropAreaAlignItems: center;
    --uploadFormDropAreaJustifyContent: center;
    --uploadFormDropAreaWidth: 500px;
    --uploadFormDropAreaHeight: 100px;
    --uploadFormDropAreaBorder: 2px dashed #4aa1f3;
    --uploadFormFileInputDisplay: none;
    --uploadFormButtonWidth: 120px;
    --uploadFormButtonHeight: 50px;
    --query_Upload_FormContainerTextAlign: center;
    --query_Upload_FormContainerWidth: 600px;
    --query_Upload_FormContainerPosition: absolute;
    --query_Upload_FormContainerLeft: 40%;
    --query_Upload_FormContainerTop: 30%;
    --query_Upload_FormContainerOutine: 1px solid black;
    --query_Upload_FormContainerPadding: 25px;
    --queryUpdaterFormFieldContainerWidth: 200px;
    --queryUpdaterFormFieldContainerHeight: 230px;
    --queryUpdaterFormFieldContainerOverflow: auto;
    --queryUpdaterFormButtonMarginTop: 3px;
    --queryUpdaterFormButtonMarginBottom: 3px;
    --queryUpdaterFormHeaderMarginBottom: 45px;
    --formAlertWidth: 325px;
    --formAlertHeight: 50px;
    /* Unauthorized Page Component Styles */

    --unauthorizedContainerTextAlign: center;
    --unauthorizedIconWidth: 300px;
    --unauthorizedIconHeight: 300px;
    --unauthorizedIconPosition: absolute;
    --unauthorizedIconLeft: 43%;
    --unauthorizedIconTop: 25%;
    /* Chart Styles */
/*    --bubbleChartContainerWidth: 100vw;
    --bubbleChartContainerHeight: 55vh;*/
    --bubbleChartContainerWidth: 546px;
    --bubbleChartContainerHeight: 515px;
}