.dropArea {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 250px;
    border: 2px dashed #4aa1f3;
    width: 75%;
}


.uploadIcon {
    width: 50px;
    height: 50px;
    background-size: 100%;
    color: #4aa1f3;
    margin: 0 auto;
}

.dropAreaMessage {
    justify-content: center;
    color: #4aa1f3;
    font-family: Arial;
    font-size: 15px;
    margin-top: 10px;
}

.fileClick {
    display: none;
}
