/* Classes for all elements rendered in Forms.js */

@import url("../ClassShaperRootStyles.css");

.scenarioFormContainer {
    position: var(--scenarioFormContainerPosition);
    top: var(--scenarioFormContainerTop);
    left: var(--scenarioFormContainerLeft);
    transform: var(--scenarioFormContainerTranslate);
    width: var(--scenarioFormContainerWidth) !important;
    height: var(--scenarioFormContainerHeight);
    border: var(--scenarioFormContainerBorder);
    box-shadow: var(--scenarioFormContainerBoxShadow);
    text-align: var(--scenarioFormContainerTextAlign) !important;
    background-color: var(--scenarioFormContainerBackgroundColor);
}

.scenarioFormHeader {
    padding: var(--scenarioFormHeaderPadding);
}

.scenarioFormTextBox {
    width: var(--scenarioFormTextBoxWidth);
}

.scenarioFormSelect {
    width: var(--scenarioFormSelectWidth);
}







.uploadFormDropArea {
    display: var(--uploadFormDropAreaDisplay);
    align-items: var(--uploadFormDropAreaAlignItems);
    justify-content: var(--uploadFormDropAreaJustifyContent);
    width: var(--uploadFormDropAreaWidth);
    height: var(--uploadFormDropAreaHeight);
    border: var(--uploadFormDropAreaBorder);
}

.uploadFormFileInput {
    display: var(--uploadFormFileInputDisplay);
}

.uploadFormButton {
    width: var(--uploadFormButtonWidth);
    height: var(--uploadFormButtonHeight);
}



.query_upload_FormContainer {
    text-align: var(--query_Upload_FormContainerTextAlign);
    width: var(--query_Upload_FormContainerWidth) !important;
    position: var(--query_Upload_FormContainerPosition);
    left: var(--query_Upload_FormContainerLeft);
    top: var(--query_Upload_FormContainerTop);
    outline: var(--query_Upload_FormContainerOutine);
    padding: var(--query_Upload_FormContainerPadding);
}

.queryUpdaterFieldContainer {
    width: var(--queryUpdaterFormFieldContainerWidth);
    height: var(--queryUpdaterFormFieldContainerHeight);
    overflow: var(--queryUpdaterFormFieldContainerOverflow);
}

.queryUpdaterFormButton {
    margin-top: var(--queryUpdaterFormButtonMarginTop) !important;
    margin-bottom: var(--queryUpdaterFormButtonMarginBottom) !important;
}

.queryUpdaterFormHeader {
    margin-bottom: var(--queryUpdaterFormHeaderMarginBottom);
}





.formAlert {
    width: var(--formAlertWidth);
    height: var(--formAlertHeight);
}